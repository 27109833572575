import React, { createContext, useState, useEffect } from 'react'
import { equals, indexOf } from 'ramda'
import {
  usePersistedState,
  totalPrice,
  isDoubleSystemCheck,
  isSimpleFasciaCheck,
  isRegularTrackCheck,
  getTrackSkuPrefix,
  getFasciaSkuPrefix,
} from '../utils/utils'
import {
  damperrr,
  synchrrro,
  track,
  wallBracket45,
  wallBracket25,
  spacer,
  woodedFasciaBracket,
  aluFascia,
  aluFasciaSimple,
  selectBaseKit,
  retrieveOpions,
} from '../molecules/Configurator/configurator'
import packageJson from '../../package.json'
import { germany } from '../initial/vars'

const simpleProduct = (
  qty,
  baseKitName,
  prodImage,
  simplePrice,
  kitId,
  simpleSku,
) => ({
  readyProduct: {
    name: baseKitName,
    image: prodImage,
    price: simplePrice,
    quantity: qty,
    product_id: kitId,
    sku: simpleSku,
  },
})

const initialTrackLength = 180

export const Context = createContext({})

export const Provider = ({ children }) => {
  const [region, setRegion] = usePersistedState(`region_${packageJson.version}`, germany ? 'DE' : 'PL')

  const [cartTotal, setCartTotal] = useState(0)
  const [cartVisible, setCartVisible] = React.useState(false)
  const showCart = () => setCartVisible(true)
  const hideCart = () => setCartVisible(false)
  const [cart, setCart] = usePersistedState(
    'productsInCart_' + packageJson.version,
    []
  )
  const [trackLength, setTrackLength] = useState(initialTrackLength)
  const [mountingWall, setMountingWall] = useState(0)
  const [doorThickness, setDoorThickness] = useState(1)
  const [dmp, setDmp] = useState(0)
  const [synchro, setSynchro] = useState(0)
  const [aluFsc, setAluFsc] = useState(0)
  const [aluFscSimple, setAluFscSimple] = useState(0)
  const [spcr, setSpcr] = useState(0)
  const [woodedFasciaBrkt, setWoodedFasciaBrkt] = useState(0)
  const [qty, setQty] = useState(1)
  const [createdSku, setCreatedSku] = useState()

  const createSku = (
    baseKitName,
    trackLength,
    woodedFasciaBrkt,
    doorThickness,
    mountingWall,
    aluFsc,
    aluFscSimple,
    dmp,
    kitId,
  ) => {
    let woodenFasciaBrktQty =
      Math.floor((trackLength + 37) / 60) * woodedFasciaBrkt
    if (woodenFasciaBrktQty === 1) {
      woodenFasciaBrktQty = 2
    }

    const spacersQty = Math.floor((trackLength + 14) / 30) * spcr
    const isDoubleSystem = isDoubleSystemCheck(kitId)
    const isSimpleFascia = isSimpleFasciaCheck(kitId)
    const isRegularTrack = isRegularTrackCheck(kitId)
    const isW50System = (kitId === 2559 || kitId === 2589)

    const fasciaSku = `FSC.${getFasciaSkuPrefix(kitId)}.${trackLength}`
    const trackSku = `TRC.${getTrackSkuPrefix(kitId)}.${trackLength}`

    const systemsSku = `${(isDoubleSystem ? 'DBL.' : '') +
      baseKitName.slice(0, 4).trim()}.${trackLength +
      '.70' +
      dmp * (synchro ? 1 : isDoubleSystem ? 2 : 1) +
      (!isW50System ? '.' + (400 + 1 * woodenFasciaBrktQty) : '') +
      (!isW50System ? '.' + (doorThickness * 100 + 500 + Math.floor((trackLength + 14) / 30) * mountingWall) : '') +
      (!isW50System ? '.' + (aluFscSimple ? 968 + aluFscSimple * 12 : (doorThickness * 20 * Boolean(aluFsc) + (aluFsc ? 900 + 13 + aluFsc * 12 : 900))) : '') +

      (kitId === 2559  ? '.' + ('SLV') : '') +
      (kitId === 2589  ? '.' + ('BLK') : '') +
      (spcr ? '.' + (800 + spacersQty) : '') +
      (synchro ? '.2S' : "")
      }`

    const sku = () => {
      if (isSimpleFascia) {
        return fasciaSku
      }
      if (isRegularTrack) {
        return trackSku
      }
      return systemsSku
    }

    setCreatedSku(sku())
    return sku()
  }

  const configuredProduct = (
    qty,
    dmp,
    synchro,
    trackLength,
    mountingWall,
    baseKitName,
    prodImage,
    kitId,
    doorThickness,
    woodedFasciaBrkt,
    aluFsc,
    aluFscSimple,
  ) => ({
    readyProduct: {
      name: baseKitName,
      image: prodImage,
      wpId: kitId,
      options: retrieveOpions(
        trackLength,
        mountingWall,
        doorThickness,
        aluFsc,
        aluFscSimple,
        woodedFasciaBrkt,
        dmp,
        synchro,
        kitId
      ),
      kitBase_S60: selectBaseKit(qty, kitId, region),
      track: track(qty, trackLength, kitId),
      damper: damperrr(qty, dmp, kitId, synchro),
      synchrrro: synchrrro(qty, synchro),
      wallBracket25: wallBracket25(
        qty,
        trackLength,
        mountingWall,
        doorThickness
      ),
      wallBracket45: wallBracket45(
        qty,
        trackLength,
        mountingWall,
        doorThickness
      ),
      woodedFasciaBracket: woodedFasciaBracket(
        qty,
        trackLength,
        woodedFasciaBrkt
      ),
      aluFascia: aluFascia({ qty, trackLength, aluFsc, kitId, doorThickness }),
      aluFasciaSimple: aluFasciaSimple({ qty, trackLength, aluFscSimple, kitId }),
      spacer: spacer(qty, spcr, trackLength, mountingWall, aluFscSimple),

      quantity: qty,
      sku: createSku(
        baseKitName,
        trackLength,
        woodedFasciaBrkt,
        doorThickness,
        mountingWall,
        aluFsc,
        aluFscSimple,
        dmp,
        kitId
      ),
    },
  })

  const filterOutSame = (arr, item) =>
    arr.filter(arrItem => !equals(item, arrItem))

  const removeGroup = (productToRemove) =>
    setCart(filterOutSame(cart, productToRemove))

  const updateQuantity = (index, val) => {
    if (cart[index].readyProduct.product_id !== undefined) {
      setCart([
        ...cart.slice(0, index),
        simpleProduct(
          parseInt(cart[index].readyProduct.quantity + val),
          cart[index].readyProduct.name,
          cart[index].readyProduct.image,
          cart[index].readyProduct.price,
          cart[index].readyProduct.product_id,
          cart[index].readyProduct.sku
        ),
        ...cart.slice(index + 1),
      ])
    } else {
      setCart([
        ...cart.slice(0, index),
        configuredProduct(
          parseInt(cart[index].readyProduct.quantity + val),
          cart[index].readyProduct.damper.quantity /
          cart[index].readyProduct.quantity,
          cart[index].readyProduct.synchrrro.quantity === 0 ? 0 : 1,
          cart[index].readyProduct.track.length,
          cart[index].readyProduct.wallBracket25.quantity !== 0 ||
            cart[index].readyProduct.wallBracket45.quantity !== 0
            ? 1
            : 0,
          cart[index].readyProduct.name,
          cart[index].readyProduct.image,
          cart[index].readyProduct.wpId,
          cart[index].readyProduct.wallBracket25.quantity !== 0 ? 0 : 1,
          cart[index].readyProduct.woodedFasciaBracket.quantity !== 0 ? 1 : 0,
          cart[index].readyProduct.aluFascia.quantity === 0 ? 0 : 1,
          cart[index].readyProduct.aluFasciaSimple.quantity === 0 ? 0 : 1,
          cart[index].readyProduct.sku
        ),
        ...cart.slice(index + 1),
      ])
    }
  }

  useEffect(() => {
    setCartTotal(
      cart.reduce((val, cartItem) => val + totalPrice(cartItem.readyProduct), 0)
    )
  }, [cart])

  useEffect(() => {
    setAluFsc(0)
    setAluFscSimple(0)
  }, [mountingWall])

  const addToCart = readyProduct => {
    let elemmm = cart.find(
      el => el.readyProduct.sku === readyProduct.readyProduct.sku
    )
    let index = indexOf(elemmm, cart)
    if (elemmm !== undefined) {
      setCart([
        ...cart.slice(0, index),
        configuredProduct(
          parseInt(elemmm.readyProduct.quantity) + parseInt(qty),
          dmp,
          synchro,
          trackLength,
          mountingWall,
          cart[index].readyProduct.name,
          cart[index].readyProduct.image,
          cart[index].readyProduct.wpId,
          doorThickness,
          woodedFasciaBrkt,
          aluFsc,
          aluFscSimple,
          createdSku
        ),
        ...cart.slice(index + 1),
      ])
    } else {
      setCart([...cart, readyProduct])
    }
  }

  const addToCartSimple = readyProduct => {
    let elemmm = cart.find(
      el => el.readyProduct.sku === readyProduct.readyProduct.sku
    )
    let index = indexOf(elemmm, cart)
    if (elemmm !== undefined) {
      setCart([
        ...cart.slice(0, index),
        simpleProduct(
          parseInt(elemmm.readyProduct.quantity) + parseInt(qty),
          cart[index].readyProduct.name,
          cart[index].readyProduct.image,
          cart[index].readyProduct.price,
          cart[index].readyProduct.product_id,
          cart[index].readyProduct.sku
        ),
        ...cart.slice(index + 1),
      ])
    } else {
      setCart([...cart, readyProduct])
    }
  }

  // Make the context object:
  const usersContext = {
    cart,
    setCart,
    addToCartSimple,
    addToCart,
    trackLength,
    setTrackLength,
    mountingWall,
    setMountingWall,
    dmp,
    setDmp,
    synchro,
    setSynchro,
    qty,
    configuredProduct,
    setQty,
    removeGroup,
    updateQuantity,
    doorThickness,
    setDoorThickness,
    cartVisible,
    hideCart,
    showCart,
    simpleProduct,
    aluFsc,
    setAluFsc,
    woodedFasciaBrkt,
    setWoodedFasciaBrkt,
    cartTotal,
    createdSku,
    region,
    setRegion,
    aluFscSimple,
    setAluFscSimple,
    spcr,
    setSpcr,
  }

  // pass the value in provider and return
  return <Context.Provider value={usersContext}>{children}</Context.Provider>
}

export const { Consumer } = Context
