import {
  isDoubleSystemCheck,
  isSimpleFasciaCheck,
  isRegularTrackCheck,
  isGlassSystemCheck,
  isFoldingSystemCheck,
  isG80SystemCheck,
} from '../../utils/utils'
import { germany } from '../../initial/vars'


export const damperrr = (qty, dmp, kitId, synchro) => {
  const isW50System = (kitId === 2559 || kitId === 2589)
  const isW40System = kitId === 976
  const isS60_S100System = (kitId === 59 || kitId === 65 || kitId === 10 || kitId === 27)
  const isDoubleSystem = isDoubleSystemCheck(kitId)
  const extraMultiplier = (synchro ? 1 : isDoubleSystem ? 2 : 1)

  const singleDamperPrice = () => {
    if (isW40System) {
     return germany ? '24.00' : '50.00'
    } else if (isW50System) {
     return germany ? '48.00' : '119.00'
    } else if (isS60_S100System) {
     return germany ? '51.00' : '154.00'
    } else {
     return germany ? '56.00' : '169.00'
    }
   }

  return ({
    quantity: qty * dmp * extraMultiplier,
    regular_price: singleDamperPrice(),
  })
}


// export const damperrr = (qty, dmp, kitId, synchro) => {
//   const isW40System = kitId === 976
//   const isDoubleSystem = isDoubleSystemCheck(kitId)
//   const extraMultiplier = (synchro ? 1 : isDoubleSystem ? 2 : 1)
//   const singleDamperPrice = () => {
//     if (isW40System) {
//       return germany ? '24.00' : '50.00'
//     } else {
//       return germany ? '42.60' : '169.00'
//     }
//   }
//   return ({
//     quantity: qty * dmp * extraMultiplier,
//     regular_price: singleDamperPrice(),
//   })
// }


export const synchrrro = (qty, synchro) => ({
  quantity: qty * synchro,
  regular_price: germany ? '25.50' : '100.00',
})

const trackExtraFee = trackLength => {
  let fee = 1
  if (trackLength >= 100 && trackLength < 250) {
    fee = germany ? 1 : 10
  } else if (trackLength >= 250 && trackLength < 260) {
    fee = 75
  } else if (trackLength >= 260) {
    fee = 75
  }
  return fee
}


const WExtraSet = (trackLength, kitId) => {
  let fee = 0
  if ((kitId === 976 || kitId === 982) && trackLength >= 181) {
    fee = germany ? 8 : 35
  }
  else if (kitId === 2488 && trackLength >= 181) {
    fee = germany ? 4 : 15
  }
  return fee
}

// const pricePerCMFascia = germany ? 0.485 : 0.7
const pricePerCM_aluFasciaSimpleSilver = germany ? 0.35 : 0.75
const pricePerCM_aluFasciaSimpleBlack = germany ? 0.5 : 1.125
const pricePerCM_aluFasciaSilver_25 = germany ? 0.38 : 0.8
const pricePerCM_aluFasciaBlack_25 = germany ? 0.58 : 1.3
const pricePerCM_aluFasciaSilver_45 = germany ? 0.43 : 0.92
const pricePerCM_aluFasciaBlack_45 = germany ? 0.67 : 1.5

const pricePerCMTrack = germany ? 0.1 : 0.42
const pricePerCMTrackW40 = germany ? 0.27 : 0.4
const pricePerCMTrackW50 = germany ? 0.27 : 0.8
const pricePerCMTrackW45 = germany ? 0.27 : 0.35
const pricePerCMTrackW60 = germany ? 0.27 : 0.4

export const track = (qty, trackLength, kitId) => {
  const isSimpleFascia = isSimpleFasciaCheck(kitId)
  const isW40System = kitId === 976
  const isW45System = kitId === 2488
  const isW60System = kitId === 982
  const isW50System = (kitId === 2559 || kitId === 2589)
  
  // const pricePerCMFascia = germany ? 0.14 : 0.45
  // const pricePerCMTrack = germany ? 0.1 : 0.4
  // const pricePerCMTrackW40 = germany ? 0.27 : 0.35
  // const pricePerCMTrackW60 = germany ? 0.27 : 0.35


  const pricePerCM = () => {

    if (isSimpleFascia) {
      return 0
    }
    else if (isW40System) {
      return pricePerCMTrackW40
    }
    else if (isW50System) {
      return pricePerCMTrackW50
    }
    else if (isW45System) {
      return pricePerCMTrackW45
    }
    else if (isW60System) {
      return pricePerCMTrackW60
    }
    else {
      return pricePerCMTrack
    }
  }

  return (
    {
      quantity: qty,
      regular_price: trackLength * pricePerCM() + trackExtraFee(trackLength) + WExtraSet(trackLength, kitId),
      length: trackLength,
    }
  )
}

export const aluFascia = ({ qty, trackLength, aluFsc, kitId, doorThickness }) => {
  const multiplier = (kitId === 909 || kitId === 911 || kitId === 2508 || kitId === 2510) ? 1 : 0

  const getPricePerCM = () => {
    if (kitId === 2508) {
      return pricePerCM_aluFasciaBlack_25
    } else if (kitId === 2510) {
      return pricePerCM_aluFasciaBlack_45
    } else if (kitId === 909) {
      return pricePerCM_aluFasciaSilver_25
    } else if (kitId === 911) {
      return pricePerCM_aluFasciaSilver_45
    } else if (aluFsc === 2) {
      if (doorThickness === 0) { return pricePerCM_aluFasciaBlack_25 }
      if (doorThickness === 1) { return pricePerCM_aluFasciaBlack_45 }
    } else if (aluFsc === 1) {
      if (doorThickness === 0) { return pricePerCM_aluFasciaSilver_25 }
      if (doorThickness === 1) { return pricePerCM_aluFasciaSilver_45 }
    } else {
      return 1
    }
  }

  return {
    quantity: qty * Boolean(aluFsc || multiplier),
    regular_price: (trackLength * getPricePerCM()) + (germany ? 8 : 20),
  }
}

export const aluFasciaSimple = ({ qty, trackLength, aluFscSimple, kitId }) => {

  const multiplier = (kitId === 2272 || kitId === 2506) ? 1 : 0
  const getPricePerCM = () => {
    if (aluFscSimple === 2 || kitId === 2506) {
      return pricePerCM_aluFasciaSimpleBlack
    } else if (aluFscSimple === 1 || kitId === 2272) {
      return pricePerCM_aluFasciaSimpleSilver
    } else {
      return 1
    }
  }
  return {
    quantity: qty * Boolean(aluFscSimple || multiplier),
    regular_price: (trackLength * getPricePerCM()) + (germany ? 6 : 12),
  }
}

export const spacer = (
  qty,
  spcr,
  trackLength,
) => ({
  quantity: qty * Math.floor((trackLength + 14) / 30) * spcr,
  regular_price: germany ? 0.98 : 2.57,
})

export const wallBracket45 = (
  qty,
  trackLength,
  mountingWall,
  doorThickness
) => ({
  quantity:
    doorThickness === 1
      ? qty * Math.floor((trackLength + 14) / 30) * mountingWall
      : 0,
  regular_price: germany ? '2' : '7',
})

export const wallBracket25 = (
  qty,
  trackLength,
  mountingWall,
  doorThickness
) => ({
  quantity:
    doorThickness === 0
      ? qty * Math.floor((trackLength + 14) / 30) * mountingWall
      : 0,
  regular_price: germany ? '2' : '7',
})

export const woodedFasciaBracket = (qty, trackLength, woodedFasciaBrkt) => {
  let woodenFasciaBrktQty = qty * Math.floor((trackLength + 37) / 60) * woodedFasciaBrkt;
  if (woodenFasciaBrktQty === 1) { woodenFasciaBrktQty = 2 }
  return ({
    quantity: woodenFasciaBrktQty,
    regular_price: germany ? '2' : '7',
  })
}

export const selectBaseKit = (qty, kitId, region) => {
  if (kitId === 10) {
    //S60
    return {
      quantity: qty,
      regular_price: germany ? region === 'DE' ? '31.50' : '38.50' : '99.00',
    }
  } else if (kitId === 59) {
    //2S60
    return {
      quantity: qty,
      regular_price: germany ? region === 'DE' ? '63.00' : '77.00' : '193.00',
    }
  } else if (kitId === 27) {
    //S100
    return {
      quantity: qty,
      regular_price: germany ? '40.50' : '110.00',
    }
  } else if (kitId === 65) {
    //2S100
    return {
      quantity: qty,
      regular_price: germany ? '81.00' : '215.00',
    }
  } else if (kitId === 19) {
    //S120
    return {
      quantity: qty,
      regular_price: germany ? '47.3' : '128.00',
    }
  } else if (kitId === 62) {
    //2S120
    return {
      quantity: qty,
      regular_price: germany ? '94.60' : '235.00',
    }
  } else if (kitId === 545) {
    //G100
    return {
      quantity: qty,
      regular_price: germany ? '91.00' : '360.00',
    }
  } else if (kitId === 555) {
    //G80
    return {
      quantity: qty,
      regular_price: germany ? '78.00' : '303.00',
    }
  } else if (kitId === 68) {
    //F25
    return {
      quantity: qty,
      regular_price: germany ? '31.50' : '99.00',
    }
  } else if (kitId === 74) {
    //F40
    return {
      quantity: qty,
      regular_price: germany ? '34.00' : '110.00',
    }
  } else if (kitId === 909 || kitId === 2508) {
    //AluFasciaWithCaps25
    return {
      quantity: qty,
      regular_price: germany ? '9' : '0',
    }
  } else if (kitId === 911 || kitId === 2510) {
    //AluFasciaWithCaps45
    return {
      quantity: qty,
      regular_price: germany ? '18' : '0',
    }
  } else if (kitId === 2272 || kitId === 2506) {
    //AluFasciaPlain 
    return {
      quantity: qty,
      regular_price: germany ? '4' : '6',
    }
  } else if (kitId === 933) {
    //Track-F
    return {
      quantity: qty,
      regular_price: germany ? '8' : '18',
    }
  } else if (kitId === 906) {
    //Track-S
    return {
      quantity: qty,
      regular_price: germany ? '8' : '18',
    }
  } else if (kitId === 2522) {
    //Track-B
    return {
      quantity: qty,
      regular_price: germany ? '8' : '18',
    }
  }
  else if (kitId === 2449) {
    //L30 System
    return {
      quantity: qty,
      regular_price: germany ? '18' : '56',
    }
  }
  else if (kitId === 2447) {
    //L40 System
    return {
      quantity: qty,
      regular_price: germany ? '15.5' : '45',
    }
  }
  else if (kitId === 976) {
    //W40 System
    return {
      quantity: qty,
      regular_price: germany ? '18.2' : '95',
    }
  }
  else if (kitId === 2488) {
    //W45 System
    return {
      quantity: qty,
      regular_price: germany ? '18.2' : '58',
    }
  }
  else if (kitId === 2559) {
    //W50 System SILVER
    return {
      quantity: qty,
      regular_price: germany ? '18.2' : '379',
    }
  }
  else if (kitId === 2589) {
    //W50 System BLACK
    return {
      quantity: qty,
      regular_price: germany ? '18.2' : '418',
    }
  }
  else if (kitId === 982) {
    //W60 System
    return {
      quantity: qty,
      regular_price: germany ? '20.2' : '95',
    }
  }
}

export const retrieveOpions = (
  trackLength,
  mounting,
  doorThickness,
  aluFsc,
  aluFscSimple,
  woodedFasciaBrkt,
  dmp,
  synchro,
  kitId
) => {
  const isSimpleFascia = isSimpleFasciaCheck(kitId)
  const isRegularTrack = isRegularTrackCheck(kitId)
  const isGlassSystem = isGlassSystemCheck(kitId)
  const isFoldingSystem = isFoldingSystemCheck(kitId)
  const isG80System = isG80SystemCheck(kitId)

  const track1 = () => ["Prowadnica", `${trackLength} cm`]
  const length1 = () => ["Długość", `${trackLength} cm`]
  const mounting1 = () => ["Mocowanie", `${mounting ? 'Do ściany' : 'Do stropu'}`]
  const mounting2 = () => mounting ? ["Grubość drzwi", `${mounting && doorThickness ? '45 mm' : '25 mm'}`] : ''
  // const aluFsc1 = () => aluFsc ? ["Maskownica alu.", "Tak"] : ''
  // const aluFsc2 = () => aluFscSimple ? ["Maskownica płaska", "Tak"] : ''
  const aluFsc1 = () => {
    if (aluFsc === 1) {
      return ["Maskownica alu.", "Srebrna"]
    } else if (aluFsc === 2) {
      return ["Maskownica alu.", "Czarna"]
    } else {
      return ''
    }
  }
  const aluFsc2 = () => {
    if (aluFscSimple === 1) {
      return ["Maskownica płaska", "Srebrna"]
    } else if (aluFscSimple === 2) {
      return ["Maskownica płaska", "Czarna"]
    } else {
      return ''
    }
  }
  const woodedFasciaBrkt1 = () => woodedFasciaBrkt ? ["Uchwyt maskow. drewn.", "Tak"] : ''
  const synchro1 = () => synchro ? ["Otwieranie synchroniczne", "Tak"] : ''
  const dmp1 = () => ["Miękkie domykanie", `${dmp ? dmp : 'Nie'}`]

  const systemOptions = () => {
    return (
      [
        track1(),
        mounting1(),
        !isGlassSystem && mounting2(),
        aluFsc1(),
        aluFsc2(),
        woodedFasciaBrkt1(),
        synchro1(),
        !isG80System && dmp1()
      ]
    )
  }

  const foldingsystemOptions = () => {
    return (
      [
        track1(),
        woodedFasciaBrkt1()
      ]
    )
  }

  const fasciaTrackOptions = () => {
    return ([length1()])
  }

  if (isSimpleFascia || isRegularTrack) {
    return fasciaTrackOptions().filter(Boolean);
  } else if (isFoldingSystem) {
    return foldingsystemOptions().filter(Boolean);
  } else {
    return systemOptions().filter(Boolean);
  }


}
